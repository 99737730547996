































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { FundManageFeeRecordDto, FundType } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "FundManageFeeRecordList",
  components: {
    PagedTableView,
    AbSelect,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundManageFeeRecordList extends Vue {
  typeList: any = [];

  queryForm = {};
  detailId = 0;

  created() {
    if (
      this.$route.name === "fundManageFeeRecordDetail" &&
      this.$route.params.id
    ) {
      this.detailId = parseInt(this.$route.params.id);
    }
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.fundManageFeeRecord.getAll(params);
  }

  // 查看详情
  jumpDetail(index: number, row: FundManageFeeRecordDto) {
    this.$router.push({
      name: "fundManageFeeRecordDetail",
      params: { id: row.id!.toString() },
    });
    this.detailId = row.id!;
  }

  // 编辑
  handleEdit(index: number, row: FundManageFeeRecordDto) {
    this.$router.push({
      name: "fundManageFeeRecordEdit",
      params: { id: row.id!.toString() },
    });
  }
}
